* {
  padding: 0;
  margin:0;
}

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.subdomains {
  display: flex;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  font-size: xx-large;
}

a{
  text-decoration: none;
  display: flex;
  flex: 1;
  padding: 0;
  margin: 0;
}

.subdomain{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.9;
  flex: 1;
  background-image: url(/public/subdomains/1.jpeg);
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  transition: opacity 0.5s;
}


.subdomain:hover{
  opacity: 1;
  cursor: pointer;
}

.subdomain2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.9;
  flex: 1;
  background-image: url(/public/subdomains/2.jpg);
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  transition: opacity 0.5s;
}

.subdomain2:hover{
  opacity: 1;
  cursor: pointer;
}

.logo2 {
  position: absolute;
  width: 20vw;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 780px) {
  .subdomains{
    font-size: x-large;
    flex-direction: column;
  }
  .logo{
    padding: 1rem 0;
  }
   img {
    width: 5rem;
  }
  .phone{
    font-size: medium;
  }
  .logo2 {
    width: 30vw;
  }
}